<template>
  <el-container v-loading.fullscreen.lock="loading">
    <el-header class="text-right">
      <a
        :href="service.domain"
        style="float: left; display: flex; align-items: left"
      >
        <img
          v-if="service.logo"
          :src="require(`@/assets/img/svg/${service.logo}`)"
          style="height: 40px; padding: 5px"
        />
      </a>
    </el-header>
    <el-main class="content">
      <el-row type="flex" justify="center" align="center">
        <el-col :xs="18" :sm="6" :md="6">
          <el-form ref="form" :model="user" :rules="rules">
            <h1 align="center">
Olvidaste tu contraseña
</h1>
            <div class="divider"></div>
            <el-form-item prop="email">
              <el-input v-model="user.email" :placeholder="$t('email')">
                <i
                  slot="prefix"
                  class="el-input__icon fa fa-envelope-square"
                ></i>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                size="mini"
                type="primary"
                :loading="loading"
                :class="'button-full-width button-' + service.code"
                @click.prevent="recovery"
              >
                Recuperar contraseña
              </el-button>
            </el-form-item>
            <el-form-item>
              <a :href="service.domain">Iniciar sesión</a>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-main>
    <el-footer
      style="
        border-top: 1px solid #e6e6e6;
        background-color: #fafafa;
        font-size: 18px;
      "
    >
      <el-row type="flex" justify="center">
        <el-col
          :sm="12"
          style="text-align: center; font-size: 0.875rem; line-height: 1.71"
        >
          <el-link
            href="http://soporte.aliaddo.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Soporte
          </el-link>
          &nbsp;-&nbsp;
          <el-link
            href="https://aliaddo.com/blog"
            target="_blank"
            rel="noopener noreferrer"
          >
            Blog
          </el-link>
          &nbsp;-&nbsp;
          <el-link
            href="https://aliaddo.com/aliaddo.co"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contactanos
          </el-link>
          &nbsp;-&nbsp;
          <el-link
            href="http://estado.aliaddo.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Estado del servicio
          </el-link>
          &nbsp;-&nbsp;
          <el-link
            href="https://www.facebook.com/aliaddo"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-facebook"></i>
          </el-link>
          &nbsp;&nbsp;
          <el-link
            href="https://twitter.com/aliaddo_"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-twitter"></i>
          </el-link>
        </el-col>
      </el-row>
    </el-footer>
    <el-footer
      style="
        border: solid 1px #e6e6e6;
        background-color: #f1f1f1;
        font-size: 18px;
      "
    >
      <el-row type="flex" justify="center">
        <el-col
          :sm="12"
          style="text-align: right; font-size: 0.875rem; line-height: 1.71"
        >
          Copyright © 2024 - ALIADDO SAS. Todos los derechos reservados
        </el-col>
      </el-row>
    </el-footer>
  </el-container>
</template>
<script>
export default {
  name: 'Recovery',
  components: {
    // VueReCaptcha
  },
  data() {
    return {
      user: {
        email: ''
      },
      service: {
        id: -1,
        code: '',
        domain: '',
        logo: ''
      },
      loading: false,
      rules: {
        email: [
          {
            type: 'email',
            required: true,
            message: 'Ingresa tu email',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    recovery() {
      const _this = this
      _this.$refs.form.validate(valid => {
        if (valid) {
          _this.loading = true
          _this.$http
            .get(
              `users/recovery/?email=${_this.user.email}&serviceId=${_this.service.id}`
            )
            .then(
              response => {
                _this.loading = false

                // _this.$swal(i18n.t('ok'), 'Se ha enviado un e-mail para que asignes una nueva contraseña!', 'success')
                _this.$alert(
                  'Se ha enviado un e-mail para que asignes una nueva contraseña, puede tardarse unos minutos!',
                  'Nueva contraseña',
                  {
                    confirmButtonText: 'OK',
                    callback: action => {
                      _this.user.email = ''

                      // this.$message({type: 'info', message: `action: ${action}`})
                    }
                  }
                )
              },
              response => {
                _this.loading = false

                //
              }
            )
        }
      })
    },
    login() {
      const _this = this
      window.location.replace(_this.service.domain)
    },
    init() {
      const _this = this
      _this.loading = true
      _this.service.id = -1
      _this.service.code = _this.$route.params.service
      if (_this.service.code === 'hub') {
        _this.service.id = 0
        _this.service.domain = '//hub.aliaddo.com'
      } else if (_this.service.code === 'fe') {
        _this.service.id = 1
        _this.service.domain = '//fe.aliaddo.com'
      } else if (_this.service.code === 'aliaddo') {
        _this.service.id = 2
        _this.service.domain = '//app.aliaddo.com'
      } else if (_this.service.code === 'hrm') {
        _this.service.id = 3
        _this.service.domain = '//nomina.aliaddo.com'
      } else if (_this.service.code === 'gasnet') {
        _this.service.id = 6
        _this.service.domain = '//gasnet.aliaddo.com'
      } else if (_this.service.code === 'isv') {
        _this.service.id = 7
        _this.service.domain = '//isv.aliaddo.com'
      } else if (_this.service.code === 'aliaddopos') {
        _this.service.id = 8
        _this.service.domain = '//app.aliaddo.com'
      } else if (_this.service.code === 'aliaddonominafull') {
        _this.service.id = 10
        _this.service.domain = '//nomina.aliaddo.com'
      } else if (_this.service.code === 'aliaddonominalite') {
        _this.service.id = 11
        _this.service.domain = '//app.aliaddo.com'
      } else if (_this.service.code === 'aliaddonominaisv') {
        _this.service.id = 12
        _this.service.domain = '//isv.aliaddo.com'
      }
      document.body.classList.add(`el-body-${_this.service.code}`)
      if (_this.service.id < 0) {
        _this.$swal('Opps', 'No existe el servicio!', 'error')
      }
      _this.service.logo = 'logo-aliaddo.svg'
      _this.loading = false
    }
  }
}
</script>
<style lang="css">
body {
  color: black !important;
  display: flex !important;
  background-size: cover !important;
  flex-direction: column !important;
  padding: 0px !important;
}
.el-input__prefix {
  padding-left: 5px;
}
body.el-body-hub > * > main {
  background-color: #c9abd1 !important;
  background-image: url('../../assets/img/background-cover.jpg') !important;
}
body.el-body-fe > * > main {
  background-color: #c1e4f8 !important;
  background-image: url('../../assets/img/background-cover.jpg') !important;
}
body.el-body-aliaddo > * > main {
  background-color: #c1e4f8 !important;
  background-image: url('../../assets/img/background-cover.jpg') !important;
}
body.el-body-hrm > * > main {
  background-color: #b4dc9f !important;
  background-image: url('../../assets/img/background-cover.jpg') !important;
}
body.el-body-isv > * > main {
  background-color: #c9abd1 !important;
  background-image: url('../../assets/img/background-cover.jpg') !important;
}
.el-header {
  background-color: #fff !important;
  color: #6c757d !important;
  line-height: 60px;
  height: 48px !important;
}
.el-form {
  padding-top: 50px;
}
.el-col {
  border-radius: 4px;
  margin-bottom: 10px;
}
.image {
  width: 100%;
  display: block;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 4px;
}
.button-full-width {
  width: 100%;
}
.el-button--primary.button-hub {
  color: #fff;
  background-color: #554188 !important;
  border-color: #554188 !important;
  height: 3em;
  font-size: 14px;
}
.el-button--primary.button-fe {
  color: #fff;
  background-color: #1a709c !important;
  border-color: #1a709c !important;
  height: 3em;
  font-size: 14px;
}
.el-button--primary.button-aliaddo {
  color: #fff;
  background-color: #1a709c !important;
  border-color: #1a709c !important;
  height: 3em;
  font-size: 14px;
}
.el-button--primary.button-hrm {
  color: #fff;
  background-color: #426b21 !important;
  border-color: #426b21 !important;
  height: 3em;
  font-size: 14px;
}
.el-button--primary.button-isv {
  color: #fff;
  background-color: #554188 !important;
  border-color: #554188 !important;
  height: 3em;
  font-size: 14px;
}
.el-button--primary.button-hub:hover {
  background-color: #9166dc !important;
  border-color: #9166dc !important;
}
.el-button--primary.button-fe:hover {
  background-color: #4891b7 !important;
  border-color: #4891b7 !important;
}
.el-button--primary.button-aliaddo:hover {
  background-color: #4891b7 !important;
  border-color: #4891b7 !important;
}
.el-button--primary.button-hrm:hover {
  background-color: #6f9452 !important;
  border-color: #6f9452 !important;
}
.el-button--primary.button-isv:hover {
  background-color: #9166dc !important;
  border-color: #9166dc !important;
}
div .el-form-item__error {
  color: #000;
}
.el-form-item.is-error .el-input__inner,
.el-form-item.is-error .el-input__inner:focus,
.el-form-item.is-error .el-textarea__inner,
.el-form-item.is-error .el-textarea__inner:focus {
  border-color: #e9f8d2;
}
.el-input .el-input__inner {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  height: 3em;
  border-color: rgb(119, 119, 119);
}
.terms {
  color: rgb(78, 78, 78);
}
.el-footer {
  flex-shrink: 0;
  padding: 16px 20px;
}
.content {
  flex: 1 0 auto;
}
</style>
